.l-footer {
	margin-top: auto;
	background: white;
	&--inner {
		max-width: $max-width;
		margin: auto;
		padding: ($padding-rhythm * 2) $padding-rhythm / 2;

		font-size: 12px;
	}

		&--links {
			margin-bottom: $padding-rhythm * 2;
		}

			&--link {
				color: black;
				white-space: nowrap;
				display: inline-block;

				&:after{
					text-decoration: none;
					content: "|";
					color: lighten(gray, 10%);
					display: inline-block;
					margin-left: 0.7em;
					margin-right: 0.5em;
				}

				&:last-of-type {
					&:after {
						content: "";
					}
				}

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}

		&--logos {
			height: 50px;
			margin-bottom: 25px;
		}

		&--logo-gov {
			display: block;
			height: 50px;
			width: 40%;
			max-width: 135px;
			float: left;
			background: no-repeat url("../images/logo_tas_gov.png");
			background-size: contain;
			background-position: 100%;
		}

		&--logo-telstra {
			display: block;
			float: right;

			width: 40%;
			max-width: 150px;
			height: 50px;
			margin-top: 2px;

			background: no-repeat url("../images/logo_telstra_air.png");
			background-size: contain;
			background-position: right;

			@at-root {
				// @hack: For when the telstra logo is an img tag, as in the Purple Wi-Fi splash page - mb

				img#{&} {
					width: 155px;
					height: auto;
					margin-top: 9px;
				}
			}

		}

		&--current-page {
			margin-bottom: 0;
		}

		&--last-modified {
			margin-top: 0;
		}

		&--spiel {

			// @note: this is effectively richtext so I'm targeting the link directly - mb

			a {
				color: #000;

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}

		}
}

@include from($bp-small) {
	.l-footer {

		&--logo-telstra {
			float: left;
			margin-left: $padding-rhythm * 3;
		}

	}
}

@include from($bp-medium) {
	.l-footer {
		&--inner {
			padding: $padding-rhythm * 2;
		}
	}
}


@include from($bp-very-large) {
	.l-footer {
		padding: ($padding-rhythm * 2) 0;
	}
}
