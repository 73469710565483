.btn {
	position: relative;
	display: inline-block;

	padding: 0.6em 1.6em 0.5em 1.6em;

	background-color: white;
	outline: 0;
	border: 1px solid white;
	border-radius: 2em;

	font-family: $alt-font;
	font-size: 15px;
	font-weight: normal;
	text-decoration: none;
	color: $wifi-blue;

	transition: $transition-default;
	cursor: pointer;

	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);
	-webkit-appearance: none;

	&:hover,
	&:focus {
		text-decoration: none;
		box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.35);
	}

	&:active {
		transform: translateY(1px);
		box-shadow: none;
	}

	&__minimal {

		box-shadow: none;
		border-color: $wifi-blue;

		&:hover,
		&:focus {
			border-color: $wifi-aqua;
			box-shadow: none;
		}
	}

	&__more {

		&:after {
			display: inline-block;
			margin-left: 0.4em;
			content: " \203A";

			font-family: Arial, sans-serif;

			transition: $transition-default;
		}

		&:hover:after,
		&:focus:after {
			color: darken($wifi-aqua, 2%);
			transform: translateX(0.1em);
		}
	}

	&__dt {

		padding-left: 3em;

		&:before {
			content: "";
			position: absolute;
			top: 45%;
			left: 0.9em;

			display: block;
			width: 1.5em;
			height: 1.5em;

			margin-top: -(1.5em / 2);

			background: url('../images/logo_brandtasmania.png') no-repeat;
			background-position: center;
			background-size: contain;
		}
	}
}
