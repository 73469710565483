$scroll-bar: 13px;

.l-tac {
	padding: 20px;
	margin-bottom: 20px;

	&--banner {
		height: 30px;
		width: 100%;
		display: block;
		background: $wifi-blue;
	}

	&--title {
		@include base-heading;
		color: $wifi-blue;
	}

	&--legal {
		position: absolute;
		right: -$scroll-bar;
		height: 100%;
		padding: 15px;
		overflow: auto;
	}

	&--legal-container {
		background-color: #ebebeb;
		border-top: 2px solid #d9d9d9;
		position: relative;
		height: 500px;
		overflow: hidden;
	}

	&--agree {
		margin: 25px auto 0 auto;
		color: white;
		max-width: 350px;
		display: block;
		text-align: center;
		background-color: $wifi-green;
		box-shadow: 0px 2px rgba(100, 100, 100, 0.3);

		&:hover {
			background-color: lighten($wifi-green, 5);
		}
	}
}
