.card {
	position: relative;

	background: white;
	box-shadow: 4px 4px 0 rgba(black, 0.1);

	padding: $padding-rhythm * 0.75 $padding-rhythm * 2;
	&__blue {
		background: $wifi-blue;
		color: white;
		.card {
			&--title,
			&--message {
				color: white;
			}

			&--message {
				display: flex;
				justify-content: space-between;
			}
		}
	}
	&--title,
	&--message {
		margin: 0;
		text-decoration: none;
		color: black;
		display: block;
	}
	&--title {
		font-size: $h3-font-size;
		font-family: $heading-font;
		position: relative;
	}
	&--message {
		position: relative;
		font-size: $aside-font-size;
		opacity: 0.8;
		> svg {
			position: absolute;
			right: calc(100% + 5px);
			width: 12px;
			height: 12px;
			margin-right: 2px;
			color: $wifi-bright-green;
			top: 50%;
			transform: translateY(-50%);
		}
		.bwooop {
			position: absolute;
			right: calc(100% + 5px);
		}
		&-link {
			color: white;
			opacity: 0.7;
			text-decoration: none;
			margin-left: auto; // Align everything else to the left.
			svg {
				color: white;
				height: 9px;
				margin-left: 5px;
				vertical-align: middle;
				width: auto;
				display: inline-block;
			}
		}
	}

	&--close {
		position: absolute;
		top: .75em;
		right: $padding-rhythm / 2;
		width: 20px;
		height: 20px;
		display: block;
		svg {
			width: 100%;
			height: 100%;
		}
	}
}
