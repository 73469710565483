/**
 * Media Queries - *Try* and make everything fit within these
 */

$bp-tiny: 320;
$bp-xsmall: 525;
$bp-small: 768;
$bp-medium: 1024;
$bp-large: 1280;
$bp-very-large: 1400;


/*
* Colours
*/

$wifi-blue: #005A96;
$wifi-green: #77942b;
$wifi-bright-green: #95BC42;
$wifi-aqua: #00BCD9;

$third-party-blue: $wifi-aqua;

$dark-gray: #484848;
$mid-gray: #6E6E6E;

$page-bg: #f3f7fa;

$body-text-color: #282425;
$minor-text-color: #9F9F9F;



/**
* Padding
*/

$padding-rhythm: 1em;
$padding-rhythm-dtop: $padding-rhythm * 2;


/**
* Fonts
*/

$heading-font: 'Fira Sans', Arial, Verdana, "Microsoft YaHei New", "Microsoft Yahei", "微软雅黑", 宋体, SimSun, STXihei, "华文细黑", sans-serif;

$alt-font: $heading-font;

$transition-default: all 200ms ease-in-out;

$h1-font-size: 21px;
$h2-font-size: 18px;
$h3-font-size: 16px;

$body-font-size: 13px;
$aside-font-size: 12px;

$h1-font-size-small: $h1-font-size * 0.9;
$h2-font-size-small: $h2-font-size * 0.9;
$h3-font-size-small: $h3-font-size * 0.9;


/**
* Misc
*/

$block-width: 500px;

$max-width: 1200px;

$overlay-zlayer: 100;
