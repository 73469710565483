.l-content-page {
	&--title {
		color: $wifi-blue;
		font-size: 1.5em;
	}

	&--inner {
		max-width: $max-width;
		margin: auto;
		padding: ($padding-rhythm * 2) $padding-rhythm / 2;

		font-size: 12px;
	}
}


@include from($bp-medium) {
	.l-content-page {
		&--title {
			font-size: 2.5em;
		}
		&--inner {
			padding: $padding-rhythm * 2;
		}
	}
}


@include from($bp-very-large) {
	.l-content-page {
		padding: ($padding-rhythm * 2) 0;
	}
}
