body {
	font-family: Arial, Verdana, sans-serif;
	font-size: 14px;
	line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $heading-font;
	font-weight: normal;
	line-height: 1.2;

	margin: 0.5em 0;
}

h1 {
	letter-spacing: -0.01em;
}

p, ul, ol, li {
	margin: 0 0 1em 0;
}


.rich-text {
	color: white;

	* {
		margin: 0;
		+ * {
			margin-top: 0.7em;
		}
	}

	a {
		color: white;
	}

	ol {
		padding-left: 1.2em;
	}

	ul, ol {
		li {
			margin-bottom: 0;
			+ li {
				margin-top: .3em;
			}
		}
	}

	img {
		width: 100%;
		height: auto;
	}

	.responsive-object {
		position: relative;
		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	&__dark {

		.rich-text {
			color: black;

			a {
				color: $wifi-blue;
			}
		}

		color: black;

		a {
			color: $wifi-blue;
		}
	}
}

@include from($bp-medium) {
	.rich-text {
		font-size: 18px;
	}
}
