.l-login {
	position: relative;
	margin-bottom: 20px;
	text-align: center;

	&--banner {
		width: 100%;
	}

	&--title {
		@include base-heading;
	}

	&--button-row {
		margin: auto;
		flex-wrap: wrap;
		height: 50px;
		width: 250px;
		display: flex;
		justify-content: space-around;
		margin-bottom: 1em;
	}

	&--social-login {
		flex-basis: 20%;
		height: 50px;

		& > svg {
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	&--block {
		@extend %block;
	}

	&--form {
		margin-bottom: 2em;
		& input {
			display: block;
			width: 250px;
			margin: auto;
			margin-bottom: 1em;
		}

		& input[type="submit"] {
			@extend .btn;
		}

		& a {
			color: white;
		}
	}

	&--signup {
		width: 250px;
		margin: auto;

		&:after {
			content: "›";
			margin-left: 6px;
		}
	}
}

@include from($bp-medium) {
	.l-login {
		position: relative;
		margin-bottom: ($padding-rhythm * 6);

		&--block {
			position: absolute;
			bottom: ($padding-rhythm * 3.5);
			left: 50%;

			margin-left: -($block-width / 2);
		}
	}
}
