.breadcrumb {

	font-family: $heading-font;
	text-decoration: none;
	color: $wifi-blue;
	display: block;
	margin-bottom: $padding-rhythm;

	&--title {
		@extend %link;
	}

	// Cant add a className to the SVG so we'll have to do this :( -JS
	svg {
		transition: $transition-default;
		height: 8px;
		width: 6px;
		margin-right: 6px;
	}

	&:hover svg {
		transform: translateX(-3px);
	}
}
