.l-header {
	&--inner {
		@include clearfix();

		max-width: $max-width;
		margin: auto;

		display: flex;
		justify-content: space-between;

		transition: background-color 300ms ease-in-out, box-shadow 300ms ease-in-out;

		position: relative;
		padding: 0 $padding-rhythm / 2;

		text-align: center;
	}
		&--banner {
			background-color: $wifi-blue;
			color: white;
			padding: 6px 0;

			> div {
				text-align: left;
			}
		}

		&--logo-gov,
		&--logo-wifi {
			margin: 0 auto 0.1em 0;
			padding: $padding-rhythm * 0.75;

			text-decoration: none;
			display: inline-block;

			&-img {
				display: block;
				width: auto;
				height: 28px;

				margin: auto;
				padding-left: 1%;

				border: 0 none !important;
				outline: 0 none !important;
			}
		}

		&--logo-wifi {
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
		}

		&--language {
			display: flex;
			align-items: flex-end;

			transition: $transition-default;

			&-text {

				float: left; // @hack: for ie9 - mb

				min-width: 0;
				margin: 0;
				padding: $padding-rhythm;

				background: none;
				border: 0 none;

				font-size: 12px;
				font-weight: 500;
				text-align: center;
				text-decoration: none;
				line-height: 1.4;

				-webkit-appearance: none;
				cursor: pointer;

				color: $wifi-blue;

				.is-active & {
					color: $body-text-color;
				}

				&:hover,
				&:focus {
					outline: none;
					color: $body-text-color;
				}

			}

			&-right {
				position: relative;
				&:before {
					content: '';
					background: mix($page-bg, $wifi-blue, 10%);
					height: 10px;
					width: 1px;
					position: absolute;
					top: 50%;
					right: 100%;
					margin-top: -5px;
				}
			}
		}

		&--logo-gov {
			float: left;
		}
		&--logo-wifi {}
		&--language {
			float: right;
			overflow: hidden;
			&-right {
				float: right;
			}
			&-left {
				float: left;
			}
		}

		&__minimal {
			background-color: white;
			box-shadow: 0 0 2px rgba($body-text-color, 0.2);
		}
}

@include from($bp-tiny + 30) {
	.l-header {
		&--logo-wifi {
			&-img {
				transition: transform 300ms ease-in-out;
				will-change: transform;
				transform: scale(1.6) translateY(0.5em) translateZ(0);
			}
		}

		&__minimal {
			.l-header {
				&--logo-wifi {
					&-img {
						transform: scale(1) translateY(0) translateZ(0);
					}
				}
			}
		}
	}
}

@include from($bp-xsmall) {
	.l-header {
		&--logo-gov,
		&--logo-wifi {
			&-img {
				height: 40px;
			}
		}

		&--logo-wifi {
			&-img {
				transform: scale(1.2) translateY(0.5em) translateZ(0);
			}
		}
	}
}

@include from($bp-medium) {
	.l-header {
		&--logo-gov,
		&--logo-wifi {
			&-img {
				height: 50px;
			}
		}
	}
}
