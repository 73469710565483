@mixin base-heading {
	@include font-smoothing();

	font-size: 24px;
	font-family: $heading-font;
	font-weight: 400;
	letter-spacing: -0.04em;
	line-height: 1.2;

	@include from($bp-small) {
		font-size: 28px;
	}
}

%block {
	position: relative;
	display: inline-block;
	max-width: $block-width;

	padding: ($padding-rhythm * 2);
	margin: -($padding-rhythm * 3.5) $padding-rhythm 0 $padding-rhythm;

	background-color: #005a96;
	border-top: 2px solid mix($wifi-blue, #fff, 85%);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

	color: #fff;

	@include from($bp-small) {
		margin-top: -100px;
	}
}

%link {
	color: $wifi-blue;

	border-bottom: 1px solid rgba($wifi-blue, 0.25);
	text-decoration: none;
	line-height: 1.1;

	transition: $transition-default;

	&:hover,
	&:focus {
		border-bottom-color: $wifi-blue;
	}
}
