.fullscreen-map {
	position: fixed;
	width: 100%;
	height: 100%;
	background: $minor-text-color;
	top: 0;
	left: 0;
	z-index: $overlay-zlayer;
	&--directions {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}
	&--close {
		position: absolute;
		top: 20px;
		right: 20px;
	}

	&--hotspot {
		position: relative;

		width: 46px;
		height: 46px;

		border-radius: 100%;
		border: 1px solid $wifi-blue;
		background: rgba($wifi-blue, 0.2);

		transform: translate(-50%, -50%);

		&:after {
			content: "";

			position: absolute;
			left: 50%;
			top: 50%;
			margin-top: -5px;
			margin-left: -5px;

			width: 10px;
			height: 10px;

			border-radius: 100%;
			background: $wifi-blue;
		}
	}
	&--geolocation {
		position: relative;

		width: 16px;
		height: 16px;

		border-radius: 100%;
		border: 1px solid $wifi-bright-green;
		background: rgba($wifi-bright-green, 0.2);

		transform: translate(-50%, -50%);

		&:after {
			content: "";

			position: absolute;
			left: 50%;
			top: 50%;
			margin-top: -5px;
			margin-left: -5px;

			width: 10px;
			height: 10px;

			border-radius: 100%;
			background: $wifi-bright-green;
		}
	}
}