.l-location-layout {
	position: relative;
	padding: $padding-rhythm $padding-rhythm / 2;
	min-height: 75vh;
	width: 100%;

	&.is-loading {
		padding-bottom: calc(#{$mapRatio}% + 1.5em + 60px - #{$padding-rhythm});

		// Account for text wrapping :\
		@include from(440) {
			padding-bottom: calc(#{$mapRatio}% + 1.5em + 30px - #{$padding-rhythm});
		}
	}

	&--header {}
	&--listing {}
	&--listing-primary {
		margin-bottom: $padding-rhythm * 2;
	}

	&--loading {
		&-message {
			position: absolute;
			top: 55%;
			left: 0;
			width: 100%;
			text-align: center;
			color: $wifi-blue;
		}
		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
		}

		&:before {
			margin-left: -25px;
			margin-top: -25px;

			width: 50px;
			height: 50px;
			border: 5px solid $wifi-blue;
			border-radius: 100%;
		}

		&:after {
			margin-left: -26px;
			margin-top: -5px;

			width: 52px;
			height: 10px;
			border: 5px solid $page-bg;

			animation: spinmebabay 1s infinite ease-in-out;
		}
	}
}
