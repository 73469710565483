@keyframes bwooop {
	0% {
		opacity: 1;
		transform: scale(0.2);
	}
	100% {
		opacity: 0;
		transform: scale(1.5);
	}
}

.bwooop {
	position: relative;
	display: inline-block;

	width: 16px;
	height: 16px;
	transform: translate(-50% -50%);

	&:before,
	&:after {
		position: absolute;
		content: "";
		display: block;
		border-radius: 100%;
	}
	&:before {
		will-change: transform;
		width: 16px;
		height: 16px;
		top: 0;
		left: 0;
		border: 1px solid rgba(white, 0.5);
		animation: bwooop 0.8s infinite ease-in;
	}
	&:after {
		width: 6px;
		height: 6px;
		top: 5px;
		left: 5px;
		background: $wifi-bright-green;
	}

	&__dark {
		&:before {
			border-color: rgba($wifi-bright-green, 0.5);
		}
	}

	&__pin {
		width: 16px;
		height: 7px;
		margin-top: 7px;
		background:
			linear-gradient(
				to right,
				rgba($wifi-bright-green, 0) 7px,
				rgba($wifi-bright-green, 1) 7px,
				rgba($wifi-bright-green, 1) 9px,
				rgba($wifi-bright-green, 0) 9px
			);
		&:before {
			top: -10px;
		}
		&:after {
			top: -5px;
		}
	}
}

