.l-map-tasmania {
	max-width: 1000px;
	margin: auto;

	padding-bottom: $padding-rhythm * 1.5;

	&--panel {
		background: white;
		width: calc(100% - 2em);
		margin: 0 $padding-rhythm;
		padding: $padding-rhythm * 2;
		box-shadow: 4px 4px 0 rgba(black, 0.1);
		&-title {
			color: $wifi-blue;
			margin-top: 0;
			margin-bottom: 1em;
			line-height: 0.8;
		}
		&-cta {
			margin-top: $padding-rhythm;
		}
	}

	#Tasmania {
		display: block;
		max-height: 700px;
		margin: 0 auto;
		width: 100%;
		height: auto;
		text {
			pointer-events: none;
			text-transform: uppercase;
		}

		tspan {
			transition: $transition-default;
		}

		[data-map-fragment="LN"],
		[data-map-fragment="KI"],
		[data-map-fragment="HS"],
		[data-map-fragment="EC"],
		[data-map-fragment="NW"],
		[data-map-fragment="WC"],
		[data-map-fragment="FI"] {
			cursor: pointer;
			transition: $transition-default;

			* {
				transition: $transition-default;
			}

			&:hover,
			&:active,
			&:focus {
				path {
					fill: rgba($wifi-blue, 0.8);
				}

				circle {
					fill: #004e89;
				}

				text,
				tspan {
					fill: #fff;
				}
			}
		}

		[data-map-fragment="KI"]:hover,
		[data-map-fragment="FI"]:hover {
			text,
			tspan {
				fill: $wifi-blue;
			}
		}
	}
}

@include from($bp-medium) {
	.l-map-tasmania {
		display: flex;
		align-items: center;

		margin-top: $padding-rhythm;
		margin-bottom: 0;

		#Tasmania {
			max-height: none;
			width: auto;
		}

		&--map,
		&--aside {
			flex-basis: 50%;
		}

		&--map {
		}

		&--aside {
			padding-left: 50px;
		}
		&--panel {
			width: auto;
			margin: 0;
		}

		&--regions {
			margin-bottom: $padding-rhythm;
		}
	}
}

// @hack: <IE11 don't calculate correct height for inline SVGs
@include from($bp-medium) {
	.l-map-tasmania {
		#Tasmania {
			min-height: 500px;
		}
	}
}

@include from($bp-very-large) {
	.l-map-tasmania {
		#Tasmania {
			min-height: 700px;
		}
	}
}
