.material-button {
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 100%;

	background: white;
	box-shadow: 0 2px 2px 0 rgba(black, 0.12);

	width: 46px;
	height: 46px;

	svg { // The icon. Can't add class :(
		width: 22px;
		height: 22px;
	}

	&__primary {
		background: #3B84F2;
	}
	&__ancillary {
		background: $dark-gray;
	}
	&__small {
		width: 32px;
		height: 32px;
		svg { // The icon. Can't add class :(
			width: 12px;
			height: 12px;
		}
	}
}