@font-face {
	font-family: "Fira Sans";
	src: url("../fonts/firasans-bold-webfont.eot");
	src: url("../fonts/firasans-bold-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("../fonts/firasans-bold-webfont.woff2") format("woff2"),
		url("../fonts/firasans-bold-webfont.woff") format("woff"),
		url("../fonts/firasans-bold-webfont.ttf") format("truetype"),
		url("../fonts/firasans-bold-webfont.svg#fira_sansbold") format("svg");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Fira Sans";
	src: url("../fonts/firasans-italic-webfont.eot");
	src: url("../fonts/firasans-italic-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("../fonts/firasans-italic-webfont.woff2") format("woff2"),
		url("../fonts/firasans-italic-webfont.woff") format("woff"),
		url("../fonts/firasans-italic-webfont.ttf") format("truetype"),
		url("../fonts/firasans-italic-webfont.svg#fira_sansitalic") format("svg");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Fira Sans";
	src: url("../fonts/firasans-regular-webfont.eot");
	src: url("../fonts/firasans-regular-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("../fonts/firasans-regular-webfont.woff2") format("woff2"),
		url("../fonts/firasans-regular-webfont.woff") format("woff"),
		url("../fonts/firasans-regular-webfont.ttf") format("truetype"),
		url("../fonts/firasans-regular-webfont.svg#fira_sansregular") format("svg");
	font-weight: normal;
	font-style: normal;
}
