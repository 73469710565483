$base-box-shadow: 2px 2px 0 rgba($minor-text-color, 0.2);

.location-list {
	padding: 0;
	margin: 0;

	&--item {
		list-style: none;
		overflow: hidden;
		margin: 0 0 1px 0;
		color: $body-text-color;
		overflow: hidden;
		transition: all 300ms ease-in-out;

		box-shadow: 2px 2px 0 rgba($minor-text-color, 0.2);

		&:last-child {
			margin: 0;
		}

		&.is-active {
			box-shadow: $base-box-shadow,
				0px 0px 10px rgba($minor-text-color, 0.2);

			z-index: 50;

			.location-list--link {
				color: $wifi-blue;
				.down-arrow-icon {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
	}


		&--link {
			transition: background 100ms ease-in-out;
			display: block;
			padding: $padding-rhythm * 0.75 $padding-rhythm;
			padding-right: 3em;

			background: white;

			color: inherit;

			text-decoration: none;

			position: relative;

			// Hackily added classname in file for now. Better than element targeting!! -JS
			.down-arrow-icon {
				width: 15px;
				position: absolute;
				right: $padding-rhythm;
				top: 50%;
				transform: translateY(-50%);
			}

			&:active {
				background: rgba(white, 0.5);
			}
		}

			&--title {
				font-family: $heading-font;
				font-size: $h2-font-size-small;
				margin-right: $padding-rhythm;
			}

			&--nearby-label,
			&--third-party-label {
				white-space: nowrap;
				font-size: 12px;
				position: relative;
				top: -1px;
			}
			&--nearby-label {
				color: $wifi-bright-green;
				.bwooop {
					position: relative;
					bottom: -1px;
				}
			}

			&--third-party-label {
				color: $third-party-blue;
				.star-icon {
					width: 8px;
					height: 8px;
					margin-right: 5px;
				}
			}

		&--content {
			background: white;
			padding: $padding-rhythm;
			padding-top: 0;
			overflow: hidden;
		}

			&--online-content {
				margin: $padding-rhythm 0;

				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				align-content: center;

				text-align: center;
			}

			&--offline-content {}
				&--map {
					margin-left: -5px;
					width: calc(100% + 10px);
					height: 0;
					padding-bottom: 65.6%;
					position: relative;
					&-img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
					&-btn {
						position: absolute;
						bottom: $padding-rhythm;
						right: $padding-rhythm;
						z-index: 1;
					}
				}
				&--map-note {
					font-size: 12px;
					color: $minor-text-color;
				}

		&--third-party-content {
			max-width: 450px;
			flex-basis: 100%;
		}
}

@include from($bp-medium) {
	.location-list {
		&--content {}
			&--government-content {
				display: flex;
			}
			&--map {
				width: 640px;
				height: auto;
				margin-right: $padding-rhythm;
			}
	}
}
