.l-notifications {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0 $padding-rhythm;
	z-index: 1;
	&--inner {}
	&--item {
		margin-bottom: 5px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

// Initial IN state
.card-fade-enter,
.card-fade-appear {
  opacity: 0.01;
	transform: translateY(5px);
}

// Transitioning IN state + transition
.card-fade-enter.card-fade-enter-active,
.card-fade-appear.card-fade-appear-active {
  opacity: 1;
	transform: translateY(0);
  transition: all 500ms ease-in;
}

// Initial OUT state
.card-fade-leave {
  opacity: 1;
	transform: translateY(0);
}

// Transitioning OUT state + transition
.card-fade-leave.card-fade-leave-active {
  opacity: 0.01;
	transform: translateY(5px);
  transition: all 300ms ease-in;
}
