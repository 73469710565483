.l-location-page {
	position: relative;
	margin-bottom: 20px;

	&--banner {
		display: block;
		width: 100%;
	}

	&--block {
		@extend %block;
	}

	&--title {
		@include base-heading;
	}
	&--subtitle {
		@include base-heading;
		font-size: 22px;
	}
	&--message {
		opacity: 0.9;
	}

	&--full-network-message {
		&-link {
			color: $page-bg;
		}
	}

	&--discover {
		position: relative;
		background-color: white;
		color: $wifi-blue;
		margin: 15px 0;
		padding-left: 50px;
		padding-right: 25px;

		display: inline-block;
		white-space: nowrap;

		&:after {
			content: "›";
		}

		&:hover,
		&:focus {
			color: #000;
		}

		&-logo {
			position: absolute;
			top: 45%;
			left: 15px;

			display: block;

			width: 25px;
			height: 25px;

			margin-top: -(25px / 2);

			background: no-repeat url(../images/logo_brandtasmania.png);
			background-position: center;
			background-size: contain;
		}
	}
}

@include from($bp-medium) {
	.l-location-page {
		position: relative;
		margin-bottom: ($padding-rhythm * 6);

		&--block {
			position: absolute;
			bottom: -($padding-rhythm * 4);
			left: 50%;

			margin-left: -($block-width / 2);
		}
	}
}

@include from(1300) {
	.l-location-page {
		&--block {
			bottom: $padding-rhythm * 4;
		}
		&--subtitle {
			@include base-heading;
			font-size: 24px;
		}
	}
}
