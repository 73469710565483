.eol-banner {
	& p,
	& ul,
	& li {
		margin: 0;
	}

	& a {
		color: white;
	}
}
