.textual-region-picker {
	padding: 0 $padding-rhythm;
	margin: 0 0 $padding-rhythm 0;

	// @hack: Make the gap between this and the map smaller. This can be removed when the map SVG is resaved with less space around it -mb
	margin-top: -$padding-rhythm;

	text-align: center;

	&--title {
		margin-top: 0;
	}

	&--nav {

		font-family: $heading-font;

	}

		&--link {
			@extend %link;

			display: inline-block;
			margin: $padding-rhythm * 0.5 $padding-rhythm;

			white-space: nowrap;
		}
}

@include from($bp-medium) {
	.textual-region-picker {
		padding: 0;
		margin-bottom: 0;
		margin-top: 0;
		font-size: 1.25em;
		text-align: left;

		&--link {
			margin-left: 0;
		}
	}
}
