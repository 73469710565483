.end-of-service {
	color: $wifi-blue;
	margin: auto 0;
	padding: ($padding-rhythm * 2) $padding-rhythm / 2;
	h1 {
		@include base-heading;
		color: $wifi-blue;
		margin-top: 0;
	}

	.service-ended {
		@extend .rich-text;
		font-family: $heading-font;
		font-style: italic;
		margin: $padding-rhythm 0;
		color: $body-text-color;
	}

	h2 {
		@include base-heading;
		color: $wifi-blue;
		font-size: $h2-font-size;
	}

	ul {
		@extend .rich-text;
		padding-left: $padding-rhythm;

		li {
			margin-bottom: $padding-rhythm / 2;
			color: $body-text-color;

			a {
				@extend %link;
			}
		}
	}
}

@include from($bp-medium) {
	.end-of-service {
		padding: ($padding-rhythm * 2);
	}
}
