input[type='text'],
input[type='email'],
input[type='password']{
	background-color: transparent;
    color: white;
    outline: none;
    border: 1px solid white;
    border-radius: 2em;
    padding: 1em;
    text-align: center;

	&::placeholder {
		opacity: 0.8;
		color: white;
	}
}
