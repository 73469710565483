$mapRatioRaw: 532 / 416;
$mapRatio: $mapRatioRaw * 100;

@keyframes spinmebabay {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.application {
	background: $page-bg;
	padding-top: 60px;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&--main {
		max-width: $max-width;
		width: 100%;
		margin: auto;
		display: flex;
		overflow: hidden;

		&.is-loading {
			// padding-bottom = Map + l-map-tasmania padding + textual-region-picker height
			padding-bottom: calc(#{$mapRatio}% + 1.5em + 60px);

			// Account for text wrapping :\
			@include from(440) {
				padding-bottom: calc(#{$mapRatio}% + 1.5em + 30px);
			}

			position: relative;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
			}

			&:before {
				margin-left: -25px;
				margin-top: -25px;

				width: 50px;
				height: 50px;
				border: 5px solid $wifi-blue;
				border-radius: 100%;
			}

			&:after {
				margin-left: -26px;
				margin-top: -5px;

				width: 52px;
				height: 10px;
				border: 5px solid $page-bg;

				animation: spinmebabay 1s infinite ease-in-out;
			}
		}
	}
}

@include from($bp-xsmall) {
	.application {
		&--main {
			&.is-loading {
				padding-bottom: calc(#{$mapRatio / 2}% + 2.5em);
			}
		}
	}
}

@include from($bp-medium) {
	.application {
		padding-top: 0;
		&--main {
			display: flex;
		}
	}
}

@include from(1000) {
	// l-map-tasmania max-width
	.application {
		&--main {
			min-height: calc(100vh - 500px);
			&.is-loading {
				$test: 721 / 1000;
				padding-bottom: calc(#{$test * 1000px} + 1em);
			}
		}
	}
}
